import React, { useEffect, useRef, useState } from 'react';
import { Info } from '../../../reusables';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import messages from '../../../../i18n/base-en';
import SkinConfigurationsUtils from '../../../../utils/SkinConfigurationsUtils';
import EpcTooltipIcon from '../../../Offers/EpcTooltipIcon';
const OfferLandingPagesCard = ({
  landingPage,
  isReferrals,
  skinConfigurations,
  viewMode,
  checked,
  handleSelectLanding,
  handleCheckId,
  isAutoOptimized,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const backgroundImageRef = useRef(null);
  const [optimizedScreenshotStyle, setOptimizedScreenshotStyle] = useState({});
  const SKIN = new SkinConfigurationsUtils(skinConfigurations);
  const getOfferItemsVisibility = () => ({
    moreInfo: SKIN.isItemVisible('offersList', 'MoreInfo'),
  });
  const IS_VISIBLE = getOfferItemsVisibility();

  // Preload optimized image for smooth transition when changing view mode
  useEffect(() => {
    const preloadImage = (src) => {
      const img = new Image();
      img.src = src;
    };

    if (isAutoOptimized && landingPage.screenshot?.mobile_thumbnail && landingPage.screenshot?.desktop_thumbnail) {
      preloadImage(landingPage.background.mobile);
      preloadImage(landingPage.background.desktop);
      preloadImage(landingPage.screenshot.mobile_thumbnail);
      preloadImage(landingPage.screenshot.desktop_thumbnail);
    }
  }, [isAutoOptimized, landingPage.screenshot]);

  const handleClick = () => {
    handleCheckId(landingPage.id);
    handleSelectLanding(landingPage.name);
  };

  useEffect(() => {
    if (landingPage.screenshot[`${viewMode}_thumbnail`] !== currentImage) {
      setIsLoaded(false);
      setHasError(false);
    }
  }, [viewMode, landingPage.screenshot, currentImage]);

  const handleScreenshotLoading = () => {
    setCurrentImage(landingPage.screenshot[`${viewMode}_thumbnail`]);
    setIsLoaded(true);
    setHasError(false);
  };

  const handleScreenshotError = () => {
    setIsLoaded(false);
    setHasError(true);
  };

  useEffect(() => {
    const calculateOptimizedScreenshotPosition = () => {
      if (backgroundImageRef.current) {
        const bgImage = backgroundImageRef.current;
        const bgWidth = bgImage.offsetWidth;
        const bgHeight = bgImage.offsetHeight;

        const desktopStyle = {
          width: `${bgWidth * 0.589}px`,
          height: `${bgHeight * 0.55}px`,
          top: `${bgHeight * 0.432}px`,
          left: `${bgWidth * 0.205}px`,
        };

        const mobileStyle = {
          width: `${bgWidth * 0.41}px`,
          top: `${bgHeight * 0.4}px`,
          left: `${bgWidth * 0.278}px`,
        };

        setOptimizedScreenshotStyle(viewMode.toLowerCase() === 'mobile' ? mobileStyle : desktopStyle);
      }
    };

    calculateOptimizedScreenshotPosition();

    let resizeObserver;
    let resizeTimeout;
    if (typeof ResizeObserver !== 'undefined' && backgroundImageRef.current) {
      resizeObserver = new ResizeObserver(() => {
        if (resizeTimeout) {
          window.cancelAnimationFrame(resizeTimeout);
        }
        resizeTimeout = window.requestAnimationFrame(() => {
          calculateOptimizedScreenshotPosition();
        });
      });
      resizeObserver.observe(backgroundImageRef.current);
    }
    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
      if (resizeTimeout) {
        window.cancelAnimationFrame(resizeTimeout);
      }
    };
  }, [viewMode, landingPage, backgroundImageRef, isLoaded]);

  return (
    <label className={`label-landing ${isAutoOptimized ? 'optimized' : ''}`} htmlFor={landingPage.id}>
      <div className={checked ? 'selected screenshot-box' : 'screenshot-box'} htmlFor={landingPage.id} key={landingPage.id}>
        <div className="screenshot-section">
          <div className="radio-field big-radio grey-radio" data-cy="landing-input-select">
            <input
              className="with-gap"
              defaultChecked={checked}
              id={landingPage.id}
              name="selected-landing"
              onClick={() => {
                handleClick();
              }}
              type="radio"
              value={landingPage.id}
            />
            <label htmlFor={landingPage.id} />
          </div>
          {isAutoOptimized ? (
            <div className="optimized-container">
              {isLoaded && hasError ? (
                <img
                  alt="Loading..."
                  id="cr-screenshot-loading"
                  src="https://www.crakrevenue.com/wp-content/uploads/2022/06/whale-anim8.gif"
                />
              ) : (
                <>
                  <img
                    alt="Landing Page Preview"
                    className="optimized-background"
                    id="screenshot-picture"
                    ref={backgroundImageRef}
                    src={landingPage.background[`${viewMode}`]}
                  />
                  <div className="small-image-container">
                    {!isLoaded && (
                      <div className="loading-overlay">
                        <img alt="Loading..." src="https://www.crakrevenue.com/wp-content/uploads/2022/06/whale-anim8.gif" />
                      </div>
                    )}
                    <img
                      alt="Landing Page Preview"
                      className={`optimized-small ${viewMode}`}
                      onError={() => handleScreenshotError()}
                      onLoad={() => handleScreenshotLoading()}
                      src={landingPage.screenshot[`${viewMode}_thumbnail`]}
                      style={optimizedScreenshotStyle}
                    />
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className={viewMode}>
              {!isLoaded && !hasError ? (
                <img
                  alt="Loading..."
                  id="cr-screenshot-loading"
                  src="https://www.crakrevenue.com/wp-content/uploads/2022/06/whale-anim8.gif"
                />
              ) : null}
              {hasError ? (
                <div className="screenshot-error">
                  <FormattedMessage {...messages.genericTextErrorWhileLoadingImage} />
                </div>
              ) : null}
              <img
                alt="Landing Page Preview"
                className={`landing-screenshot ${!isLoaded ? 'hidden' : ''}`}
                onError={() => handleScreenshotError()}
                onLoad={() => handleScreenshotLoading()}
                src={landingPage.screenshot[`${viewMode}_thumbnail`]}
              />
            </div>
          )}
        </div>
        <div className="landing-details">
          <p
            className={landingPage.id === 'optimized' ? 'landing-name featured_container' : 'landing-name'}
            key={landingPage.id}
            value={landingPage.id}
          >
            {landingPage.id === 'optimized' && <img alt="Featured" className="featured" src="/img/featured-star.svg" />}

            {landingPage.name}
          </p>
          {!isReferrals && (
            <span className="epc">
              <FormattedMessage {...messages.offerEPC} />
              {IS_VISIBLE.moreInfo ? <EpcTooltipIcon closeIcon id={`tooltip-epc-${landingPage.id}`} /> : ' '}
              <span>
                {landingPage.epc_affiliation ? (
                  landingPage.id === 'optimized' ? (
                    landingPage.epc_affiliation
                  ) : (
                    <FormattedNumber
                      currency="USD"
                      minimumFractionDigits={4}
                      style="currency"
                      value={parseFloat(landingPage.epc_affiliation || 0)}
                    />
                  )
                ) : (
                  <FormattedMessage {...messages.notEnoughData} />
                )}
              </span>
            </span>
          )}
          <div className="preview-landing-page ">
            {landingPage.id === 'optimized' ? (
              <p>{landingPage.description}</p>
            ) : (
              <a className="primary-color" href={landingPage.preview_url} rel="noopener noreferrer" target="_blank">
                <i className="material-icons">remove_red_eye</i>

                <FormattedMessage {...messages.genericTextPreviewModal} />
              </a>
            )}
          </div>
        </div>
      </div>
    </label>
  );
};
export default OfferLandingPagesCard;
